define("discourse/plugins/discourse-user-search/discourse/components/searched-user-info", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators", "discourse/lib/url", "discourse/lib/settings"], function (_exports, _component, _computed, _decorators, _url, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.normalize = normalize;
  function normalize(name) {
    return name.replace(/[\-\_ \.]/g, "").toLowerCase();
  }
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: [":user-info", "size"],
    attributeBindings: ["data-username"],
    size: "small",
    "data-username": (0, _computed.alias)("user.username"),
    userPath(username) {
      return (0, _url.userPath)(username);
    },
    name(name, username) {
      if (name && normalize(username) !== normalize(name)) {
        return name;
      }
    },
    nameFirst(name) {
      return (0, _settings.prioritizeNameInUx)(name);
    }
  }, [["method", "userPath", [(0, _decorators.default)("user.username")]], ["method", "name", [(0, _decorators.default)("user.name", "user.username")]], ["method", "nameFirst", [(0, _decorators.default)("user.name")]]]));
});