define("discourse/plugins/discourse-user-search/discourse/discourse_user_search-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route('discourse_user_search', {
      path: '/discourse_user_search'
    });
  }
});