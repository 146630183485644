define("discourse/plugins/discourse-user-search/discourse/routes/discourse_user_search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Route for the path `/evidence_uploader` as defined in `../discourse_user_search-route-map.js.es6`.
   */
  var _default = _exports.default = Discourse.Route.extend({
    renderTemplate() {
      this.render('discourse_user_search');
    }
  });
});